// React
import {
  createContext,
  ReactNode, useEffect,
  useState
} from 'react';
import AzureAuth from "../azure-component/AzureAuth";
import { AccountInfo } from '@azure/msal-browser';
import axios from "axios";
import { CLASSTER_TENANTS_BY_UUID } from 'src/@constants/tenants-constants';
import {SuperAdminUsersEmail} from "../@constants";

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

export type UserInfo = {
  role: string,
  permissions: string[],
  first_name: string,
  last_name: string,
  email: string,
  token: string,
  account: Record<string, any>,
  user_id: string,
  user_name: string,
}

type PlayerInfo = {
  score: number,
  badges: number,
  progress: number,
}

export type AuthInterface = {
  isAuthenticated: boolean,
  onAuth: (userName?: string, password?: string) => void,
  logout: VoidFunction,
  user: UserInfo | null,
  player: PlayerInfo | null,
  notifications: {
    unread: number,
  },
  tenant: Tenant | null,
  isSuperAdmin: boolean,
};

export type Tenant = {
  name: string,
  id: string,
}

/**
 * @description - Worlds base context to create context of world lifecycle
 */
export const ContextAuth = createContext<AuthInterface | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [account, setAccount] = useState<AccountInfo | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserInfo | null>(null);
  const [player, setPlayer] = useState<PlayerInfo | null>(null);
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  /**
   * @description Getting the role from the PROSYS platform
   *  available roles are
   *
   * ADMINISTRATOR = 1
   * GUEST = 2
   * AUTHENTICATED_USER = 3
   * STUDENT = 4
   * TEACHER = 5
   * INSTITUTE_DIRECTOR = 6  # Institute Director
   * COUNSELLOR = 7
   * ISSSC = 8  # Student Support Coordinator
   * IT_ADMIN = 9  # IT-Manager / Administrator
   * STUDENT_MENTOR = 10
   * DEPUTY_DIRECTOR_IEU = 11  # Deputy Director Inclusive Education Unit (IEU)
   * DIRECTOR_APPRENTICESHIP_LSU = 12  # Director of Apprenticeship / Deputy Director Learning Support Unit (LSU)  # noqa E501
   * RESEARCHER = 13  # Researcher / Assistant Researcher
   * PRINCIPAL = 14  # Principal / Deputy Principal
   * LEARNING_SUPPORT_EDUCATOR = 15
   * DEPUTY_INSTITUTE_DIRECTOR = 16  # Deput
   */
  const getRole = async () => {
    // try {
    //   if (typeof token === 'string') {
    //     const response = await axios.get(
    //       'https://api.otis-platform.tech/api/v0/auth/user/self/',
    //       { headers: { Authorization: `Bearer ${token}` } }
    //     );
    //
    //     return response.data; // Return the data from the response
    //   }
    // } catch (error) {
    //   // Handle error
    //   console.error('Error fetching data:', error);
    // }
    console.log(account)
    return {
      user_id: account?.localAccountId,
      role: 'administrator',
      permissions: [],
      first_name: '',
      email: 'otis.researcher@mcast.edu.mt'
    };
  }

  const getUserData = async (user_id: string) => {
    try {
      if (typeof token === 'string') {
        const response = await axios.get(
          `https://api.otis-platform.tech/api/v1/prosys/councillor/students/${user_id}/data/`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        return response.data;
      }
    } catch (error) {
      console.log(error)
    }
  };

  const logout = () => {
    AzureAuth.logout();
  }

  useEffect(() => {
    if (!account) {
      setAccount(AzureAuth.getAccount());
    }
    AzureAuth.getToken().then((tokenData) => typeof tokenData === 'string' ? setToken(tokenData) : null).catch(console.log);
  }, []);

  useEffect(() => {
    if (account && token) {
      getRole().then((roleData) => {
        setUser({
          account,
          user_id: roleData?.user_id ?? 'na',
          role: roleData?.role ?? 'administrator',
          permissions: roleData?.permissions ?? [],
          user_name: account?.name ?? 'na',
          first_name: roleData?.first_name ?? 'na',
          last_name: roleData?.first_name ?? 'na',
          email: roleData?.email ?? 'na',
          token,
        });
        if (SuperAdminUsersEmail.includes(roleData?.email?.toLowerCase())) {
          setIsSuperAdmin(true);
        }
      });
      if (SuperAdminUsersEmail.includes(account?.username?.toLowerCase())) {
        setIsSuperAdmin(true);
      }
    }
  }, [token, account]);

  useEffect(() => {
    if (user && typeof user?.account?.tenantId === "string") {
      const user_tenant = CLASSTER_TENANTS_BY_UUID[user.account.tenantId];
      if (user_tenant) {
        setTenant({
          name: user_tenant,
          id: user.account.tenantId
        })
      }

      if (token) {
        getUserData(user.user_id);
      }
    }
  }, [user]);

  // The games visibility state variable
  const [
    isAuthenticated,
    setIsAuthenticated
  ] = useState<boolean>(false);

  const onAuth = (userName?: string, password?: string) => {
    // ToDo if needed custom auth
  };

  return (
    <ContextAuth.Provider
      value={{
        isAuthenticated,
        onAuth,
        logout,
        user,
        player,
        notifications: { unread: 0 },
        tenant,
        isSuperAdmin
      }}
    >
      {children}
    </ContextAuth.Provider>
  );
}
