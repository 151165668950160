import axios from 'axios';
import {TWorld, TMiniWorld, TypeUserInfo} from 'src/@types';
import { GamesListDemo, WorldDemo } from 'src/constants/games-demo';
import {
  convertDbDataToWorld,
  convertDbDataToMiniWorldGamesList,
  convertDbDataToMiniWorld,
} from './utils';
import { IGameView } from "../../@interfaces";
import { gamesFetcher } from 'src/api/v0';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The parameter variable for the fetching the world data by
 * course id
 * @param {string} courseId - The course id where the world is attached.
 *  that is converting the server data into the internal object
 * @param {function} onSuccess - The method that is triggering when successfully
 *  executed the fetching process. The method pass the converted data
 * @param {function} onFail - The method is executing when there is an error
 *  during the fetching process from the server
 */
interface FetchWorldByCourseIdProps {
  courseId: string;
  onSuccess: (data: TWorld) => void;
  onFail: (message: Error | string) => void;
}

/**
 * @description - The method is fetching the world data from the server.
 */
export function fetchWorldByCourseId(
  { courseId, onSuccess, onFail }: FetchWorldByCourseIdProps
) {
  onSuccess(convertDbDataToWorld(WorldDemo));
}

/**
 * @description - The parameter variable for the fetching the mini world data by
 * its ID
 * @param {string} id - The mini world id where.
 * @param {function} onSuccess - The method that is triggering when successfully
 *  executed the fetching process. The method pass the converted data
 * @param {function} onFail - The method is executing when there is an error
 *  during the fetching process from the server
 */
interface FetchMiniWorldByIdProps {
  id: string;
  onSuccess: (data: TMiniWorld) => void;
  onFail: (message: Error | string) => void;
}

/**
 * @description - The service method that fetching the mini world data from the
 * server that are corresponding to the selected mini world id
 */
export function fetchMiniWorldById(
  { id, onSuccess, onFail }: FetchMiniWorldByIdProps
) {
  onSuccess(
    convertDbDataToMiniWorld(WorldDemo.worlds.find((miniWorld) => miniWorld.id === id) as unknown as Record<string, any>)
  );
}

/**
 * @description - The parameter variable for the fetching the games' info list
 * data by mini world id
 * @param {string} miniWorldId - The provided world id
 * @param {function} onSuccess - On success method that will be executed when
 *  the server fetching succeeded and will pass all data
 * @param {function} onFail - The failed method that will be executed when there
 *  is an error during fetching execution
 */
interface FetchAllGamesByMiniWorldIdProps {
  miniWorldId: string;
  user: TypeUserInfo;
  onSuccess: (data: IGameView[]) => void;
  onFail: (message: string) => void;
}

/**
 * @description - The service method that fetching the all games lists from the
 * server that are corresponding to the selected world id
 */
export function fetchAllGamesByMiniWorldId(
  { miniWorldId, onSuccess, onFail, user }: FetchAllGamesByMiniWorldIdProps
) {
  console.log(user.account.tenantId)
  gamesFetcher(miniWorldId, user.account.tenantId ?? 'na', (data: Record<string, any>[]) => onSuccess(convertDbDataToMiniWorldGamesList(data)), onFail);
  // onSuccess(convertDbDataToMiniWorldGamesList(
  //   GamesListDemo.filter(game => game.mini_world_id === miniWorldId)
  // ));
}
